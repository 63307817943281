import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/text-area/accessibility",
  "title": "TextArea - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Placeholder text can be used to give hints and examples to the user what should be entered to the field. `}<strong parentName="li">{`However, all assistive technologies do not threat placeholder texts as labels and therefore they may ignore them completely.`}</strong>{` Aim to provide the sufficient information to fill the input in the label.`}</li>
      <li parentName="ul">{`It is required to use `}<inlineCode parentName="li">{`autocomplete`}</inlineCode>{` attribute if input control is listed in the common input purposes list. You can read the full list of common input purposes at `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#input-purposes" mdxType="ExternalLink">{`WCAG 2.1 documentation website`}</ExternalLink>{`. You can read more about the requirement from `}<ExternalLink href="https://www.w3.org/WAI/WCAG21/Understanding/identify-input-purpose" mdxType="ExternalLink">{`WCAG 2.1 Identify Input Purpose guideline`}</ExternalLink>{`.`}</li>
      <li parentName="ul">{`In the case of an erroneous input, aim to provide clear instructions to the user on how to correct the mistake. Always provide a text description of the error.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      